import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { CONFIGURATION_TYPES } from 'state-domains/constants';
import { Header } from 'state-domains/domain';

import {
    buildConfigurationAddHeaderSectionUrl,
    buildConfigurationAddSampleHeaderSectionUrl,
} from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';
import { ConfigurationAddSectionHeaderResponse } from '../types';

export const addHeaderSection = (
    payload: any,
    headerId: string,
    configType: CONFIGURATION_TYPES,
): Observable<ConfigurationAddSectionHeaderResponse> => {
    const url =
        configType === CONFIGURATION_TYPES.HEADERS
            ? buildConfigurationAddHeaderSectionUrl(headerId)
            : buildConfigurationAddSampleHeaderSectionUrl(headerId);
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const header = convertToCamel<Header>(response.header);
            const id = response.new_section._id;
            return observableOf({ header, newSectionId: id });
        }),
    );
};
